<template>
  <div>
    <institution-form
      :is-toggle-sidebar.sync="isToggleSidebar"
      :form-type="formType"
      :data-edit="dataEdit"
      @refetch-data="refetchData"
      @error-data="showError"
    />

    <e-i-directory-form
      :is-toggle-sidebar-directory.sync="isToggleSidebarDirectory"
      :form-type-directory="formTypeDirectory"
      :data-edit-directory="dataEditDirectory"
      :directory-sel="directorySel"
      @refetch-data="refetchData"
      @error-data="showError"
    />

    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="query"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
              <b-button
                variant="primary"
                @click="openForm('new')"
              >
                <span class="text-nowrap">Agregar</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="fields"
        primary-key="idTrabajador"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No se encontraron registros"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              variant="primary"
              class="align-middle"
            />
            <strong class="ml-1">Cargando...</strong>
          </div>
        </template>

        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="50"
                :src="`data:image/jpeg;base64,${data.item.foto}`"
                :text="avatarText(`${data.item.name}`)"
                :variant="`light-primary`"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.name }}
            </span>
            <small class="text-muted">Tipo: {{ data.item.typeInstitution.description }}</small>
          </b-media>
        </template>

        <template #cell(ubigeo)="data">
          <div class="d-flex">
            <div>
              <div class="font-small-2 text-muted">
                {{ data.item.address }}
              </div>
              <div class="font-small-2 text-muted">
                {{ `${data.item.ubigeoAll.dpto} / ${data.item.ubigeoAll.prov} / ${data.item.ubigeoAll.dist}` }}
              </div>
            </div>
          </div>
        </template>

        <template #cell(show_details)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            plain
            class="vs-checkbox-con"
            @change="row.toggleDetails"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                <i class="vs-icon feather icon-check" />
              </span>
            </span>
            <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
          </b-form-checkbox>
        </template>

        <template #row-details="row">
          <b-table
            :items="row.item.directorio"
            :fields="fieldsDirectorio"
            striped
            responsive
            caption-top
            show-empty
            empty-text="No se encontraron registros"
          >
            <template #table-caption>
              <span class="text-center text-white font-weight-bolder">DIRECTORIO: {{ row.item.name }}</span>
            </template>
            <template #head(acciones)>
              <b-button
                v-b-tooltip.hover.right="`Agregar Personal Aliado`"
                variant="primary"
                size="sm"
                @click="addDirectory('new', row.item)"
              >
                <feather-icon
                  icon="UserPlusIcon"
                  class="mx-auto"
                />
              </b-button>
            </template>
            <template #cell(firstName)="directorio">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="30"
                    :src="`data:image/jpeg;base64,${directorio.item.photo}`"
                    :text="avatarText(`${directorio.item.firstName} ${directorio.item.lastNameOne}`)"
                    :variant="`light-success`"
                  />
                </template>
                <span class="font-weight-bold d-block text-nowrap">
                  {{ directorio.item.firstName }}
                </span>
                <small class="text-muted">{{ directorio.item.lastNameOne }} {{ directorio.item.lastNameTwo }}</small>
              </b-media>
            </template>
            <template #cell(acciones)="directorio">
              <b-button
                v-b-tooltip.hover.top="`Editar Personal Aliado`"
                variant="light-dark"
                class="btn-icon"
                @click="getEditDirectory(row.item, directorio.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover.top="`Eliminar Directorio`"
                variant="light-dark"
                class="btn-icon"
                @click="getDeleteDirectory(directorio.item)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </template>
          </b-table>
        </template>

        <template #cell(email)="data">
          <b-link
            v-b-tooltip.hover.top="`Ver Directorio`"
            target="_blank"
            class="font-weight-bold"
            @click="data.toggleDetails"
          >
            <b-avatar
              :id="`btn-direct-${data.item.idEducationalInstitution}`"
              variant="light-primary"
              size="32"
              :badge-variant="data.item.directorio.length > 0 ? 'success' : 'dark'"
              :badge="data.item.directorio ? data.item.directorio.length + '' : '0'"
              class="mr-50"
            >
              <feather-icon icon="UserIcon" />
            </b-avatar>
          </b-link>
          <b-link
            v-if="data.item.phone != '' && data.item.phone != null"
            v-b-tooltip.hover.top="`Teléfono: ${data.item.phone}`"
            :href="`tel:${data.item.phone}`"
            target="_blank"
            class="font-weight-bold"
          >
            <b-avatar
              :id="`btn-phone-${data.item.idEducationalInstitution}`"
              size="32"
              :variant="`light-info`"
            >
              <feather-icon
                icon="SmartphoneIcon"
              />
            </b-avatar>
          </b-link>
          <b-link
            v-if="data.item.email != '' && data.item.email != null"
            v-b-tooltip.hover.top="`Enviar Correo: ${data.item.email}`"
            :href="data.item.email"
            target="_blank"
            class="font-weight-bold ml-50"
          >
            <b-avatar
              :id="`btn-email-${data.item.idEducationalInstitution}`"
              size="32"
              :variant="`light-primary`"
            >
              <feather-icon
                icon="MailIcon"
              />
            </b-avatar>
          </b-link>
          <b-link
            v-if="data.item.website != '' && data.item.website != null"
            v-b-tooltip.hover.top="`Ir a Sitio Web: ${data.item.website}`"
            :href="data.item.website"
            target="_blank"
            class="font-weight-bold ml-50"
          >
            <b-avatar
              :id="`btn-website-${data.item.idEducationalInstitution}`"
              size="32"
              :variant="`light-danger`"
            >
              <feather-icon
                icon="GlobeIcon"
              />
            </b-avatar>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(acciones)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="getEdit(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <b-dropdown-item @click="getDelete(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Eliminar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando {{ from }} a {{ to }} de {{ of }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="page"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BDropdown, BDropdownItem, BPagination, BMedia, BAvatar, BLink, VBTooltip, BSpinner, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import { showToast, showError } from '@/helpers'
import InstitutionForm from './InstitutionForm.vue'
import EIDirectoryForm from './directory/EIDirectoryForm.vue'

export default {
  components: {
    InstitutionForm,
    EIDirectoryForm,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BMedia,
    BAvatar,

    vSelect,
    BSpinner,
    BFormCheckbox,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    isToggleSidebar: false,
    isToggleSidebarDirectory: false,
    isBusy: false,
    dataEdit: {},
    dataEditDirectory: {},
    directorySel: {},
    formType: '',
    formTypeDirectory: '',
    page: 1,
    perPage: 10,
    totalRows: 0,
    totalItems: 0,
    totalPage: 0,
    from: 0,
    to: 0,
    of: 0,
    pageOptions: [10, 20, 50],
    sortBy: null,
    sortDesc: false,
    sortDirection: 'asc',
    query: '',
    items: [],
    fields: [
      { key: 'show_details', thClass: 'd-none', tdClass: 'd-none' },
      { key: 'idInstitution', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
      { key: 'name', label: 'Institución', sortable: true },
      { key: 'ubigeo', label: 'Ubicación', sortable: true },
      { key: 'email', label: 'Contacto', sortable: true },
      { key: 'acciones' },
    ],
    fieldsDirectorio: [
      { key: 'idEducationalInstitutionDirectory', sortable: false, thClass: 'd-none', tdClass: 'd-none' },
      { key: 'firstName', label: 'Autoridad', sortable: false, tdClass: 'bkwhite' },
      { key: 'phone', label: 'Contacto', sortable: false, tdClass: 'bkwhite' },
      { key: 'position', label: 'Cargo', sortable: false, tdClass: 'bkwhite' },
      { key: 'acciones', tdClass: 'bkwhite' },
    ],
  }),
  watch: {
    perPage: {
      handler() {
        this.listItems()
      },
    },
    page: {
      handler() {
        this.listItems()
      },
    },
    query: {
      handler() {
        this.listItems()
      },
    },
  },
  mounted() {
    this.listItems()
  },
  methods: {
    avatarText,
    async listItems() {
      this.isBusy = true
      await store
        .dispatch('ppptcd/INSTITUTION_FIND_ALL', {
          idTypeInstitution: -1,
          limit: this.perPage,
          query: this.query,
          page: this.page,
          sortBy: 'idInstitution%7CDESC',
          type: 'grilla',
        })
        .then(response => {
          this.items = response.items
          this.totalRows = response.totalRows
          this.totalItems = response.totalItems
          this.totalPage = response.totalPage
          this.from = this.perPage * (this.page - 1) + (this.totalRows ? 1 : 0)
          this.to = this.perPage * (this.page - 1) + this.totalRows
          this.of = this.totalItems
        })
        .catch(error => {
          this.showToast('Ha ocurrido un error', error, 'danger')
        })
      this.isBusy = false
    },
    openForm(type) {
      this.formType = type
      this.isToggleSidebar = true
    },
    getEdit(data) {
      this.formType = 'edit'
      this.isToggleSidebar = true
      this.dataEdit = data
    },
    getEditDirectory(data, dataDirectory) {
      this.formTypeDirectory = 'edit'
      this.directorySel = data
      this.dataEditDirectory = dataDirectory
      this.isToggleSidebarDirectory = true
    },
    getDelete(data) {
      this.$swal({
        title: 'Está seguro?',
        text: 'Va eliminar un registro!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store
            .dispatch('ppptcd/INSTITUTION_DELETE', {
              id: data.idInstitution,
            })
            .then(response => {
              this.listItems()
              this.showToast('Operación Extitosa', 'Ha Eliminado correctamente el registro.', 'success')
              return response
            })
            .catch(error => {
              this.showToast('Ha ocurrido un error', error, 'danger')
            })
        }
      })
    },
    getDeleteDirectory(data) {
      this.$swal({
        title: 'Está seguro?',
        text: 'Va eliminar un registro!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store
            .dispatch('ppptcd/INSTITUTION_DIRECTORY_DELETE', {
              id: data.idInstitutionDirectory,
            })
            .then(response => {
              this.listItems()
              this.showToast('Operación Extitosa', 'Ha Eliminado correctamente el registro.', 'success')
              return response
            })
            .catch(error => {
              this.showToast('Ha ocurrido un error', error, 'danger')
            })
        }
      })
    },
    addDirectory(type, data) {
      this.directorySel = data
      this.formTypeDirectory = type
      this.isToggleSidebarDirectory = true
    },
    refetchData(response) {
      let message = 'El proceso se ha ejecutado satisfactoriamente.'
      if (response.message) {
        message = response.message
      }
      this.showToast('Operación Extitosa', message, 'success')
      this.listItems()
    },
    showToast,
    showError,
  },
}
</script>
<style>
.b-table-details{
  background: darkslateblue !important;
}
.bkwhite {
  background: white !important;
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
